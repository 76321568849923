import React from "react";
import SEO from "../../components/layout/seo";
import ExpertSignup from "../../components/signup/expertSignup";

export default function ExpertSignupPage() {
  return (
    <>
      <SEO title="Expert Sign up" />
      <ExpertSignup />
    </>
  );
}
